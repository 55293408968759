import { useContext, useState } from "react"
import { Button, CircularProgress, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { Close, Send } from "@mui/icons-material";
import { useOktaAuth } from "@okta/okta-react";
import { ModuleContext } from "../context/ModuleContext";

export const ResetPassword = ({userId}) => {

    const [passwordReset, setPasswordReset] = useState({triggered: false, waiting: false, result: null});

    const {setModule} = useContext(ModuleContext);
    
    const {authState} = useOktaAuth();
    
    const sendPasswordReset = async() => {

        setPasswordReset((prevPassReset) => ({...prevPassReset, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/password`,{
            body: JSON.stringify({
                userId: userId,
                callerId: authState.accessToken.claims.sub
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => {
            return {status: res.status, data: res.json()};
        })
        .then((result) => {
            if(result.status === 200){
                setPasswordReset((prevPassReset) => ({...prevPassReset, waiting: false, result: "Password reset link sent"}));
            }else{
                setPasswordReset((prevPassReset) => ({...prevPassReset, waiting: false, result: "Attempt to send password reset email failed"}));
            }
        });

    }
    
    if(passwordReset.waiting){
        return(
            <div key="password-reset-waiting-div" id="container-div"><CircularProgress key="reinvite-waiting" id="progress" size={100} thickness={10}/></div>
        );
    }else{
        if(!passwordReset.triggered){
            return(
                <div key="password-reset-container" id="container-div">
                    <p key="password-reset-header">{`Send Password Reset request for ${userId}?`}</p><br />
                    <Table key="password-reset-table">
                        <TableBody key="password-reset-table-body">
                            <TableRow key="password-reset-table-row">
                                <TableCell key="password-reset-send-button-cell">
                                    <Button key="password-reset-send-button" variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => sendPasswordReset()}>Yes</Button>
                                </TableCell>
                                <TableCell key="password-reset-cancel-button-cell">
                                    <Button key="password-reset-cancel-button" variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            );
        }else{
            return(
                <div key="password-reset-result-div" id="container-div">
                    <p key="password-reset-result">{passwordReset.result}</p>
                </div>
            );
        }
    }

}