import { Fragment, useContext } from "react"
import { ModuleContext } from "../context/ModuleContext"
import { ValidateIdentity } from "../operations/ValidateIdentity";
import { Dialog, DialogContent, IconButton, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));

export const ModuleContainer = () =>{

    const {module, setModule} = useContext(ModuleContext);


    if(!module.validated && !module.isLog){
        if(module.validationError){
            return(
                <div>
                    <p>
                        {module.validationError}
                    </p>
                </div>
            )
        }else{
            return ( <Fragment>
                <BootstrapDialog
                  onClose={() => setModule((prev) => ({...prev, isVisible: false, element: null, userId: null}))}
                  aria-labelledby="customized-dialog-title"
                  open={module.isVisible}
                >
                  <IconButton
                    aria-label="close"
                    onClick={() => setModule((prev) => ({...prev, isVisible: false, element: null, userId: null}))}
                    sx={(theme) => ({
                      position: 'absolute',
                      right: 8,
                      top: 8,
                      color: theme.palette.grey[500],
                    })}
                  >
                    <CloseIcon />
                  </IconButton>
                  <DialogContent style={{padding: "30px"}}>
                    <ValidateIdentity userId={module.userId}/>
                  </DialogContent>
                </BootstrapDialog>
              </Fragment>);
        }

    }else{
        return ( <Fragment>
            <BootstrapDialog
              onClose={() => setModule((prev) => ({...prev, isVisible: false, element: null, userId: null, isLog: false}))}
              aria-labelledby="customized-dialog-title"
              open={module.isVisible}
              maxWidth={"xl"}
            >
              <IconButton
                aria-label="close"
                onClick={() => setModule((prev) => ({...prev, isVisible: false, element: null, userId: null, isLog: false}))}
                sx={(theme) => ({
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                })}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent style={{padding: "60px"}}>
                {module.element}
              </DialogContent>
            </BootstrapDialog>
          </Fragment>);
    } 
}