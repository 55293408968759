import { Close, Send } from "@mui/icons-material";
import { Button, CircularProgress, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { useState, useContext } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { ModuleContext } from "../context/ModuleContext";

export const PhishedNid = ({userId}) => {
    const [state, setState] = useState({error: null, waiting: false, success: false});

    const {setModule} = useContext(ModuleContext);
    
    const {authState} = useOktaAuth();
    
    const phishedNid = async() => {

        setState((prev) => ({...prev, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/phished`,{
            body: JSON.stringify({
                networkId: userId,
                callerId: authState.accessToken.claims.sub
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => res.json())
        .then((result) => {
            setState((prev) => ({...prev, waiting: false, success: true}));
        },
        (error) => {
            console.error(error);
            setState((prev) => ({...prev, waiting: false, error: error}));
        })

    }
    
    if(state.waiting){
        return(
            <div key="phished-nid-waiting-div" className="container-div"><CircularProgress key="phished-nid-waiting" style={{color: "#A60F2D" }} size={100} thickness={10}/></div>
        );
    }else if(state.error){
        return(<div key="phished-nid-error-div" className="container-div">
            <p key="phished-nid-error">{state.error.message}</p>
        </div>);
    }else if(state.success){
        return(<div key="phished-nid-success-div" className="container-div">
            <p key="phished-nid-success">{`Account ${userId} has been suspended and their password is expired`}</p>
        </div>);
    }
    else{
        return(
            <div key="phished-nid-table-cont" className="container-div">
            <p key="phished-nid-table-header">{`Suspend account and expire password for ${userId}?`}</p><br />
            <Table key="phished-nid-table">
                        <TableBody key="phished-nid-table-body">
                            <TableRow key="phished-nid-table-row">
                                <TableCell key="phished-nid-activate-button-cell">
                                    <Button key="phished-nid-activate-button" variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => phishedNid()}>Yes</Button>
                                </TableCell>
                                <TableCell key="phished-nid-decline-cell">
                                <Button key="phished-nid-decline-button" variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
            </div>
        );
    }
}