import { useEffect, useState } from "react"
import { Button, CircularProgress, Table, TableBody, TableCell, TableRow, TextField, ThemeProvider, useTheme } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useOktaAuth } from "@okta/okta-react";
import { InputTheme } from "../themes/InputTheme";

export const ModifySecondaryEmail = ({userId}) => {
    const [state, setState] = useState({isLoaded: false, error: null, success: false});

    const [text, setText] = useState("Enter a new Secondary Email...");

    const {authState} = useOktaAuth();

    const outerTheme = useTheme();


    useEffect(() => {
        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/email/secondary/lookup`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
                
            },
            body: JSON.stringify({
                "userId": userId,
                "callerId": authState.accessToken.claims.sub
            })
        })
        .then((res) => res.json())
        .then((result) => {
            setState((prevState) => ({...prevState, isLoaded: true}));
            if(result.secondaryEmail !== null){
                setText(result.secondaryEmail);
            }
        },
        (error) => {
            setState((prevState) => ({...prevState, isLoaded: true, error: error}));
        })
    }, []);


    const modifySecondaryEmail = async() => {
        setState((prevState) => ({...prevState, isLoaded: false}));       
        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/email/secondary/set`,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "userId": userId,
                "secondaryEmail": text,
                "callerId": authState.accessToken.claims.sub
            })
        })
        .then((res) => {
            return {statusCode: res.status, data: res.json()};
        })
        .then((result) => {
            console.log(result.statusCode);
            if(result.statusCode === 200){
                setState((prevState) => ({...prevState, isLoaded: true, success: true, error: null}));
            }else{
                setState((prev) => ({...prev, isLoaded: true, success: false, error: {message: JSON.stringify(result.data)}}));
            }
        })
    }

    if(!state.isLoaded){
        return (<div key="secondary-email-loading-cont" id="container-div"><CircularProgress key="secondary-email-loading" id="progress" size={100} thickness={10}/></div>);
    }else if(state.error){
        return (<div key="secondary-email-success-container" id="container-div">
            <ThemeProvider theme={InputTheme(outerTheme)}>
            <Table key="secondary-email-table">
                <TableBody key="secondary-email-table-body">
                    <TableRow key="secondary-email-row">
                        <TableCell key="secondary-email-cell"> <TextField key="secondary-email" required id="module-input" label="Edit Secondary Email" value={text} onChange={(e) => setText(e.target.value)} /></TableCell>
                        <TableCell key="secondary-email-button-cell"><Button key="secondary-email-send-button" variant="contained" id="right-button" endIcon={<Send />} onClick={(e) => modifySecondaryEmail()}>Submit New Secondary Email</Button></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </ThemeProvider>
            <div key="secondary-email-success-message-div">
                <p key="secondary-email-success">Secondary Email Updated Failed.  Please verify that the value provided is an email and that it does not match the primary email.</p>
            </div>           
        </div>);
    }else if(state.success){
        return (<div key="secondary-email-success-container" id="container-div">
            <ThemeProvider theme={InputTheme(outerTheme)}>
            <Table key="secondary-email-table">
                <TableBody key="secondary-email-table-body">
                    <TableRow key="secondary-email-row">
                        <TableCell key="secondary-email-cell"> <TextField key="secondary-email" required id="module-input" label="Edit Secondary Email" value={text} onChange={(e) => setText(e.target.value)} /></TableCell>
                        <TableCell key="secondary-email-button-cell"><Button key="secondary-email-send-button" variant="contained" id="right-button" endIcon={<Send />} onClick={(e) => modifySecondaryEmail()}>Submit New Secondary Email</Button></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </ThemeProvider>
            <div key="secondary-email-success-message-div">
                <p key="secondary-email-success">Secondary Email Updated Successfully</p>
            </div>           
        </div>);
    }
    else{
        return (<div key="secondary-email-success-container" id="container-div">
            <ThemeProvider theme={InputTheme(outerTheme)}>
            <Table key="secondary-email-table">
                <TableBody key="secondary-email-table-body">
                    <TableRow key="secondary-email-row">
                        <TableCell key="secondary-email-cell"> <TextField key="secondary-email" required id="module-input" label="Edit Secondary Email" value={text} onChange={(e) => setText(e.target.value)} /></TableCell>
                        <TableCell key="secondary-email-button-cell"><Button key="secondary-email-send-button" variant="contained" id="right-button" endIcon={<Send />} onClick={(e) => modifySecondaryEmail()}>Submit New Secondary Email</Button></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            </ThemeProvider>       
        </div>);
    }


}