import { useEffect, useState } from "react"
import { Paper,TableContainer, Table,TableHead, TableBody, TableRow, TableCell, TablePagination, CircularProgress } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";

export const ActivityLog = ({userId}) =>{


    const [state, setState] = useState({isLoaded: false, data: null, error: null});

    const {authState} = useOktaAuth();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() =>{

        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/logs/action`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "username": userId
            })
        })
        .then( (res) => res.json())
        .then(
            (result) => {
                setState((prevState) => ({...prevState, data: result.data, isLoaded: true}));
            },
            (error) => {
                setState((prevState) => ({...prevState, isLoaded: true, error: error}));
            }
        )

    }, [])


    if(!state.isLoaded){
        return <div key="activity-log-loading" id="container-div"><CircularProgress key="activity-log-loader" id="progress" size={100} thickness={10}/></div>;
    }else if(state.error){
        return <div key="activity-log-error-container" id="container-div"><p key="activity-log-error">{state.error.message}</p></div>;
    }else if(state.data.length === 0){
        return <div key="activity-log-no-records-container" id="container-div"><p key="activity-log-no-records">No records found</p></div>;
    }else{
        return(
            <div key="activity-log-div" id="container-div">
            <Paper key="activity-log-paper">
            <TableContainer key="activity-log-container" sx={{ maxHeight: 440 }}>
              <Table key="activity-log-table" stickyHeader aria-label="sticky table">
                <TableHead key="activity-log-table-head">
                  <TableRow key="activity-log-header-row">
                    <TableCell key="username">
                        User Name
                    </TableCell>
                    <TableCell key="action">
                        Event Action
                    </TableCell>
                    <TableCell key="actor">
                        Event Actor
                    </TableCell>
                    <TableCell key="comment">
                        Comment
                    </TableCell>
                    <TableCell key="process">
                        Process
                    </TableCell>
                    <TableCell key="time">
                        Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.username + "-" + row.event_time}>
                            <TableCell key={ row.username + "-" + row.event_time + "-username"}>
                                {row.username}
                            </TableCell>
                            <TableCell key={row.username + "-" + row.event_time + "-action"}>
                                {row.event_action}
                            </TableCell>
                            <TableCell key={row.username + "-" + row.event_time + "-actor"}>
                                {row.event_actor}
                            </TableCell>
                            <TableCell key={row.username + "-" + row.event_time + "-comment"}>
                                {row.event_comment}
                            </TableCell>
                            <TableCell key={row.username + "-" + row.event_time + "-process"}>
                                {row.event_process}
                            </TableCell>
                            <TableCell key={row.username + "-" + row.event_time + "-time"}>
                                {row.event_time}
                            </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination key="table-pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
            </div>
        )
    }



}