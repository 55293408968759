import { useOktaAuth } from "@okta/okta-react";
import "../css/CrimsonServiceDeskHeader.css";
import { Button } from "@mui/material";

export const CrimsonServiceDeskHeader = () => {

    const {oktaAuth} = useOktaAuth();

    return (
        <header className="crimsonServiceDeskHeader">
            <h1>WSU Tech Tools</h1>
            <div className="nav">
            <ul>
                <li><Button style={{color: "white"}} onClick={() => oktaAuth.signOut()}>Log Out</Button></li>
            </ul>
            </div>
        </header>
    )
}