import { createContext } from "react";

export const UserModuleContext = createContext({
    userModule: {
        isOpen: false,
        userId: null,
        isLoaded: false,
        isRestricted: false
    },
    setUserModule: () => ({})
});