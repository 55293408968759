import { useContext, useState } from "react";
import { ModuleContext } from "../context/ModuleContext";
import { useOktaAuth } from "@okta/okta-react";
import { Button, CircularProgress, Table, TableBody, TableRow, TableCell } from "@mui/material";
import { Close, Send } from "@mui/icons-material";

export const Reinvite = ({userId}) => {


    const [reinvite, setReinvite] = useState({triggered: false, waiting: false, result: null});

    const {setModule} = useContext(ModuleContext);
    
    const {authState} = useOktaAuth();
    
    const sendReinvite = async() => {

        setReinvite((prev) => ({...prev, triggered: true, waiting: true}));

        await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/reinvite`,{
            body: JSON.stringify({
                userId: userId,
                callerId: authState.accessToken.claims.sub
            }),
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            }

        })
        .then((res) => res.json())
        .then((result) => {
            setReinvite((prev) => ({...prev, waiting: false, result: "Reinvite email sent"}));
        },
        (error) => {
            setReinvite((prev) => ({...prev, waiting: false, result: "Attempt to send reinvite email failed"}));
        })

    }
    
    if(reinvite.waiting){
        return(
            <div key="reinvite-waiting-div" id="container-div"><CircularProgress key="reinvite-waiting" id="progress" size={100} thickness={10}/></div>
        );
    }else{
        if(!reinvite.triggered){
            return(
                <div key="reinviate-form-div" id="container-div">
                    <p key="reinvite-header">{`Send Reinvite request for ${userId}?`}</p><br />
                    <Table key="reinvite-table">
                        <TableBody key="reinviate-table-body">
                            <TableRow key="reinvite-table-row">
                                <TableCell key="reinvite-send-button-cell">
                                    <Button key="reinvite-button" variant="contained" id="left-button" endIcon={<Send />} onClick={(e) => sendReinvite()}>Yes</Button>
                                </TableCell>
                                <TableCell key="reinvite-cancel-button-cell">
                                <Button key="reinvite-cancel-button" variant="contained" id="right-button" endIcon={<Close />} onClick={(e) => setModule((prev) => ({...prev, isVisible: false}))}>No</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            );
        }else{
            return(
                <div key="reinvite-result-div" id="container-div">
                    <p key="reinvite-result">{reinvite.result}</p>
                </div>
            );
        }
    }


}