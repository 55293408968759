import { useState, useContext} from "react";
import { Paper, TableContainer, Table, TableBody, TableRow, TableCell, Button, Typography, CircularProgress } from "@mui/material";
import { ModuleContext } from "../context/ModuleContext";
import { Send } from "@mui/icons-material";
import { useOktaAuth } from "@okta/okta-react";

export const ValidateIdentity = ({userId}) => {

    const [state, setState] = useState({isLoaded: true, error: null, data: null, validating: false, selectedOption: "", message: "Detail the validation method used here..."});

    const {setModule} = useContext(ModuleContext);

    const {authState} = useOktaAuth();
   
    // const getTwoFactors = useEffect(() => {
    //     fetch(`https://wsuwa.workflows.oktapreview.com/api/flo/519fc23b17138c05313d9f530769c327/invoke?clientToken=dfa12767e3df58d5c3dd6a0efc2229e8658d78953ab65ec4cb7075c5437308d2&username=${userId}`)
    //     .then((res) => res.json())
    //     .then(
    //         (result) => {
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 isLoaded: true,
    //                 data: result
    //             }));
    //         },
    //         (error) => {
    //             setState((prevState) => ({
    //                 ...prevState,
    //                 isLoaded: true,
    //                 error: error
    //             }));
    //         }
    //     )
    // }, []);


    // const sendTwoFactorNotification = async(factorId) =>{
    //     setState((prevState) => ({...prevState, validating: true}));
    //     await fetch(`https://wsuwa.workflows.oktapreview.com/api/flo/90f3efa66a666ce0848ff012bafc33c7/invoke?clientToken=4738a1454b38309d134a1d2704458ef7c82d3c927f466e5d9787f6bf028ad1f0`,{
    //         body: JSON.stringify({
    //             callerId: authState.accessToken.claims.sub,
    //             username: userId,
    //             factorId: factorId
    //         }),
    //         method: "POST",
    //         cache: "no-store",
    //     })
    //     .then((res) => res.json())
    //     .then((result) => {
    //         if(result.verifyResponse.factorResult === "SUCCESS"){
    //             setModule((prevModule) => ({...prevModule, validated: true}));
    //         }else{
    //             setModule((prevModule) => ({...prevModule, validationError: "Could not validate with factor " + factorId}));
    //         }
    //     })
    // }

    const alternateValidate = async () =>{
        if(state.selectedOption !== ""){
            setState((prevState) => ({...prevState, validating: true}));
            let theMessage = "";
    
            if(state.selectedOption === "verifiedInPerson"){
                theMessage = `Verified in person`;
            }else if(state.selectedOption === "verifiedOverZoom"){
                theMessage = `Verified over zoom`;
            }else{
                theMessage = state.message
            }
    
            await fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/logs`,
                {
                    body: JSON.stringify({
                        target_username: userId,
                        actor_username: authState.accessToken.claims.sub,
                        action: state.selectedOption,
                        comment: theMessage,
                        api_process: "validateUser",
                        user_process: state.selectedOption
                    }),
                    method: "POST",
                    headers: {
                        "Authorization": authState.accessToken.accessToken,
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                }
            )
            .then((res) => res.json())
            .then((result) => {
                setModule((prevModule) => ({...prevModule, validated: true}));
            },
            (error) => {
                setModule((prevModule) => ({...prevModule, validationError: error.message}));
            })
        }else{
            setState((prev) => ({...prev, isLoaded: true, error: {message: "You must select a validation option"}}));
        }
    }

    if(!state.isLoaded || state.validating){
        return (<div key="validation-loading-div" id="container-div"><CircularProgress id="progress" size={100} thickness={10}/></div>);
    }else if(state.error){
        return (<div key="validation-error-message-div" id="container-div">
            <p key="validation-error-message">{state.error.message}</p>
        </div>);
    }else{
        return (
            <div key="validation-menu-div" id="container-div">
                {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Typography variant="h6" gutterBottom component="div">
                            Validate with MFA
                        </Typography>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Factor Name</TableCell>
                                    <TableCell>Factor Type</TableCell>
                                    <TableCell>Factor ID</TableCell>
                                    <TableCell>Device Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {state.data.factors
                                    .map((row) => {
                                    if(row.factorType === "push"){
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                <TableCell>{row.profile.name}</TableCell>
                                                <TableCell>{row.factorType}</TableCell>
                                                <TableCell>{row.id}</TableCell>
                                                <TableCell>{row.profile.deviceType}</TableCell>
                                                <Button variant="contained" endIcon={<Send />} style={{backgroundColor: "#981e32"}} onClick={(e) => sendTwoFactorNotification(row.id)}>Send Push</Button>
                                            </TableRow>
                                        );
                                    }
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <br /> */}
                <Paper key="validation-paper">
                    <TableContainer key="validation-container">
                        <Typography key="validation-typography" variant="h6" gutterBottom component="div">
                            Validation Options
                        </Typography>
                        <Table key="validation-table">
                            <TableBody key="validation-table-body">
                                <TableRow key="validation-verified-in-person-row">
                                    <TableCell key="validation-vip-radio-cell">
                                        <input key="validation-vip-input" type="radio" value="verifiedInPerson" checked={state.selectedOption === "verifiedInPerson"} onChange={(e) => setState((prevState) => ({...prevState, selectedOption: e.target.value}))} /> 
                                    </TableCell>
                                    <TableCell key="validation-vip-label-cell">
                                        Verified In Person
                                    </TableCell>
                                </TableRow>
                                <TableRow key="validation-verified-over-zoom-row">
                                    <TableCell key="validation-voz-radio-cell">
                                        <input key="validation-voz-input" type="radio" value="verifiedOverZoom" checked={state.selectedOption === "verifiedOverZoom"} onChange={(e) => setState((prevState) => ({...prevState, selectedOption: e.target.value}))} />
                                    </TableCell>
                                    <TableCell key="validation-voz-label-cell">
                                        Verified Over Zoom
                                    </TableCell>
                                </TableRow>
                                <TableRow key="validation-other-row">
                                    <TableCell key="validation-other-radio-cell">
                                        <input key="validation-other-input" type="radio" value="verifiedOther" checked={state.selectedOption === "verifiedOther"} onChange={(e) => setState((prevState) => ({...prevState, selectedOption: e.target.value}))} />
                                    </TableCell>
                                    <TableCell key="validation-other-label-cell">
                                        Other
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div key="validation-other-text-div" id="left-div">{state.selectedOption === "verifiedOther" &&
                                          <div key="validation-other-text-cont"><textarea key="validation-other-text" rows={10} cols={150} value={state.message} onChange={(e) => setState((prevState) => ({...prevState, message: e.target.value}))}/><br /></div>
                                }
                        <Button key="validation-send-button" endIcon={<Send />} variant="contained" id="left-button" onClick={(e) => alternateValidate()}>Verify</Button></div>
                    </TableContainer>
                </Paper>
            </div>
        )
    }

}