
import { Button, TextField, ThemeProvider, useTheme } from "@mui/material";
import { InputTheme } from "../themes/InputTheme";

export const SearchBar = ({text,onTextChange,placeholderValues,handleClick}) => {
  const outerTheme = useTheme();
    return (
          <div id="search-bar">             
            <ThemeProvider theme={InputTheme(outerTheme)}>
            <TextField key="search-input" className="search-input" size="medium" required id="outlined-required" label="Search by Account Name/WSUID" value={text} onChange={(e) => onTextChange(e.target.value)} onKeyDown={(e) => {
                if(e.key === "Enter"){handleClick();}}} />
              <Button id="search-button" variant="contained" onClick={(e) => handleClick()}>Search</Button>
            </ThemeProvider>
          </div>
      );
}