import { useOktaAuth } from "@okta/okta-react";
import { Paper,TableContainer, Table,TableHead, TableBody, TableRow, TableCell, TablePagination, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react"

export const OktaLog = ({userId}) => {
    const [state, setState] = useState({loaded: false, error: null, data: null});

    const {authState} = useOktaAuth();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() =>{

        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/logs/okta`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "userId": userId
            })
        })
        .then( (res) => res.json())
        .then(
            (result) => {
                setState((prevState) => ({...prevState, data: result.logs, loaded: true}));
            },
            (error) => {
                setState((prevState) => ({...prevState, loaded: true, error: error}));
            }
        )

    }, []);


    if(!state.loaded){
        return <div key="okta-logs-loading-cont" id="container-div"><CircularProgress key="okta-log-loading" id="progress" size={100} thickness={10}/></div>;
    }else if(state.error){
        return <div key="okta-logs-error-cont" id="container-div"><p key="okta-log-error-message">{state.error.message}</p></div>;
    }else if(state.data.length === 0){
        return <div key="okta-log-no-records-cont" id="container-div"><p key="okta-logs-no-records">No records found</p></div>;
    }else{
        return(
            <div key='okta-logs-cont' id="container-div">
            <Paper key="okta-logs-paper" sx={{ width: '100%', overflow: 'hidden' }}>
            <TableContainer key="okta-logs-table-cont" sx={{ maxHeight: 440 }}>
              <Table key="okta-logs-table" stickyHeader aria-label="sticky table">
                <TableHead key="okta-logs-table-head">
                  <TableRow key="okta-logs-table-head-row">
                    <TableCell key="okta-logs-username">
                        User Name
                    </TableCell>
                    <TableCell key="otka-logs-action">
                        Event Action
                    </TableCell>
                    <TableCell key="okta-logs-actor">
                        Event Actor
                    </TableCell>
                    <TableCell key="okta-logs-ip">
                        IP Address
                    </TableCell>
                    <TableCell key="okta-logs-region">
                        Region
                    </TableCell>
                    <TableCell key="okta-logs-time">
                        Time
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key="okta-logs-table-body">
                  {state.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={row["Raw Output"].target[0].alternateId + "-" + row.Published}>
                            <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-username"}>
                                {row["Raw Output"].target[0].alternateId}
                            </TableCell>
                            <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-action"}>
                                {row["Display Message"]}
                            </TableCell>
                            <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-actor"}>
                                {row.Actor["Display Name"]}
                            </TableCell>
                            <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-ip"}>
                                {row["Raw Output"].client.ipAddress}
                            </TableCell>
                            {
                                row.Client["Geographical Context"] !== null &&
                                <TableCell key={row.code + "-region"}>
                                {row["Raw Output"].client.geographicalContext.city + "," + row["Raw Output"].client.geographicalContext.state + "," + row["Raw Output"].client.geographicalContext.country}
                                </TableCell>
                            }
                                                        {
                                row.Client["Geographical Context"] === null &&
                                <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-region"}>
                                </TableCell>
                            }
                            <TableCell key={row["Raw Output"].target[0].alternateId + "-" + row.Published + "-time"}>
                                {row.Published}
                            </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination key="okta-logs-pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
            </div>
        )
    }

}