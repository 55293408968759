import { createContext } from "react";

export const ModuleContext = createContext({
    module: {
        isVisible: false,
        element: null,
        isRestricted: false,
        validated: false,
        validationError: null,
        userId: null,
        isLog: false
    },
    setModule: () => ({})
})