import { createTheme } from "@mui/material";

export const InputTheme = (outerTheme) =>
    createTheme({
        palette: {
          mode: outerTheme.palette.mode,
        },
        components: {
          MuiTextField: {
            styleOverrides: {
              root: {
                '--TextField-brandBorderColor': '#E0E3E7',
                '--TextField-brandBorderHoverColor': '#B2BAC2',
                '--TextField-brandBorderFocusedColor': '#A60F2D',
                '& label.Mui-focused': {
                  color: 'var(--TextField-brandBorderFocusedColor)',
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              notchedOutline: {
                borderColor: 'var(--TextField-brandBorderColor)',
              },
              root: {
                [`&:hover .MuiOutlinedInput-notchedOutline`]: {
                  borderColor: 'var(--TextField-brandBorderHoverColor)',
                },
                [`&.Mui-focused .MuiOutlinedInput-notchedOutline`]: {
                  borderColor: 'var(--TextField-brandBorderFocusedColor)',
                },
              },
            },
          },
          MuiFilledInput: {
            styleOverrides: {
              root: {
                '&::before, &::after': {
                  borderBottom: '2px solid var(--TextField-brandBorderColor)',
                },
                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                  borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                },
                '&.Mui-focused:after': {
                  borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                },
              },
            },
          },
          MuiInput: {
            styleOverrides: {
              root: {
                '&::before': {
                  borderBottom: '2px solid var(--TextField-brandBorderColor)',
                },
                '&:hover:not(.Mui-disabled, .Mui-error):before': {
                  borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
                },
                '&.Mui-focused:after': {
                  borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
                },
              },
            },
          },
        },
      });
  