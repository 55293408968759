import { useContext, useState } from "react"
import { ActivityLog } from "../operations/ActivityLog";
import { ModuleContext } from "../context/ModuleContext";
import { ResetPassword } from "../operations/ResetPassword";
import { ModifySecondaryEmail } from "../operations/ModifySecondaryEmail";
import { AccountStatus } from "../operations/AccountStatus";
import { RemoveMFAFactor } from "../operations/RemoveMFAFactor";
import { useOktaAuth } from "@okta/okta-react";
import { NidRename } from "../operations/NidRename";
import { Reinvite } from "../operations/Reinvite";
import { OktaLog } from "../operations/OktaLog";
import { PhishedNid } from "../operations/PhishedNid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { OktaGroups } from "../operations/OktaGroups";
import { O365Info } from "../operations/O365Info";

export const OptionsMenu = ({username, identifier, isRestricted}) =>{

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const {module, setModule} = useContext(ModuleContext);

    const actions = [];

    const {authState} = useOktaAuth();

    actions.push({
        id: "accountHistory",
        action: "Show Tech Tools Action History",
        handler: () =>{
            setModule((prevModule) => ({...prevModule, isVisible: true, userId: username, element: <ActivityLog userId={identifier} />, isLog: true}));
        }
    });

    actions.push({
        id: "oktaLogs",
        action: "Get Okta User Logs",
        handler: () =>{
            setModule((prev) => ({...prev, isVisible: true, userId: username, element: <OktaLog userId={identifier} />, isLog: true}))
        }
    });

    if(!isRestricted && (authState.accessToken.claims.itsTier2 || authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier2 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "passwordReset",
            action: "Reset Password",
            handler: () =>{
                setModule((prevModule) => ({...prevModule, isVisible: true,userId: username, element: <ResetPassword userId={identifier} />}));
            }
        });
    }

    if(!isRestricted && (authState.accessToken.claims.itsTier2 || authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier2 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "modifySecondaryEmail",
            action: "Add/Edit Secondary Email",
            handler: () => {
                setModule((prevModule) => ({...prevModule, isVisible: true, userId: username, element: <ModifySecondaryEmail userId={identifier} /> }));
            }
        })
    }

    if(!isRestricted && (authState.accessToken.claims.itsTier1 || authState.accessToken.claims.distributedItTier1 || authState.accessToken.claims.itsTier2 || authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier2 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "resendInvite",
            action: "Resend Okta Invitation",
            handler: () =>{
                setModule((prev) => ({...prev, isVisible: true, userId: identifier, element: <Reinvite userId={identifier} />}));
            }
        });
    }

    if(!isRestricted && (authState.accessToken.claims.itsTier1 || authState.accessToken.claims.distributedItTier1 || authState.accessToken.claims.itsTier2 || authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier2 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "oktaGroups",
            action: "List All Okta Groups for NID/FID",
            handler: () =>{
                setModule((prev) => ({...prev, isVisible: true, isLog: true, userId: identifier, element: <OktaGroups userId={identifier} />}));
            }
        });
    }

    if(!isRestricted && (authState.accessToken.claims.itsTier1 || authState.accessToken.claims.distributedItTier1 || authState.accessToken.claims.itsTier2 || authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier2 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "o365Info",
            action: "List NID's O365 Information",
            handler: () =>{
                setModule((prev) => ({...prev, isVisible: true, userId: identifier, element: <O365Info identifier={identifier} />}));
            }
        });
    }

    if(!isRestricted && (authState.accessToken.claims.itsTier3 || authState.accessToken.claims.distributedItTier3)){
        actions.push({
            id: "resetOktaFactors",
            action: "Get/Reset Okta Factors",
            handler: () =>{
                setModule((prevModule) => ({...prevModule, isVisible: true, userId: username, element: <RemoveMFAFactor identifier={identifier} /> }));
            }
        });
    }

    if(!isRestricted && authState.accessToken.claims.itsTier3){
        actions.push({
            id: "nidOps",
            action: "Rename NID/FID",
            handler: () =>{
                setModule((prevModule) => ({...prevModule, isVisible: true, userId: username, element: <NidRename userId={identifier} /> }));
            }
        });
    }

    if(!isRestricted && authState.accessToken.claims.itsTier3){
        actions.push({
            id: "accountActions",
            action: "Suspend/Unsuspend Account",
            handler: () =>{
                setModule((prevModule) => ({...prevModule, isVisible: true, userId: username, element: <AccountStatus identifier={identifier} /> }));
            }
        });
    }

    if(!isRestricted && authState.accessToken.claims.itsTier3){
        actions.push({
            id: "phished",
            action: "Disable Phished NID",
            handler: () => {
                setModule((prev) => ({...prev, isVisible: true, userId: username, element: <PhishedNid userId={username}/>}));
            }
        })
    }

    return(

        <div>
        <IconButton
          id="basic-button"
          key={"options-menu-button"}
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <Settings />
        </IconButton>
        <Menu
          key={"options-menu"}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
            {actions.map((action) => {
                return(
                    <MenuItem key={action.id} onClick={action.handler}>{action.action}</MenuItem>
                );
            })}
        </Menu>
      </div>

    )


}