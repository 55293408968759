import { useState } from "react";
import { CrimsonServiceDeskHeader } from "./component/CrimsonServiceDeskHeader";
import { SearchBar } from "./component/search/SearchBar";
import { SearchResultTable } from "./component/tables/SearchResultTable.js";
import "./App.css";
import { UserModuleContext } from "./component/context/UserModuleContext.js";
import { UserContainer } from "./component/tables/UserContainer.js";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { Feedback, LibraryBooks } from "@mui/icons-material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

export const Home = () => {
    const [text, setText] = useState('');

    const [linkVal, setLinkVal] = useState("");

    const history = useHistory();

    const [searchResultTable, setSearchResultTable] = useState(null);
  
    const [userModule, setUserModule] = useState({isOpen: false, userId: null, isRestricted: false, isLoaded: false});
  
    const handleClick = () =>{
        setSearchResultTable(null);
        setSearchResultTable(<SearchResultTable key={"search-result-" + text} text={text} />);
    }

    const handleChange = (event, newValue) => {
      window.location.replace(newValue);
    }
  
    if(searchResultTable !== null || searchResultTable !== undefined){
      return (
        <div className="App">
        <CrimsonServiceDeskHeader />
          <div className='search-container'>
              <SearchBar text={text} onTextChange={setText} handleClick={handleClick}/>
          </div>
          <UserModuleContext.Provider value = {{userModule, setUserModule}}>
            <UserContainer />
            {searchResultTable}
          </UserModuleContext.Provider>
          <BottomNavigation className={"csd-link-container"} value={linkVal} onChange={handleChange} showLabels={true}>
            <BottomNavigationAction label="Crimson Service Desk Portal" value={"https://jira.esg.wsu.edu/servicedesk/customer/portal/34"} icon={<Feedback/>}/>
            <BottomNavigationAction label="Tech Tools User Guide" value={"https://confluence.esg.wsu.edu/display/KB/Tech+Tools+Users+Guide"} icon={<LibraryBooks />} />
          </BottomNavigation>
        </div>
        );
  }else{
      return (
        <div className="App">
        <CrimsonServiceDeskHeader />
          <div className='search-container'>
              <SearchBar text={text} onTextChange={setText} placeholderValues={"NID/FID/WSUID"} handleClick={handleClick}/>
          </div>
          <BottomNavigation className={"csd-link-container"} value={linkVal} onChange={handleChange} showLabels={true}>
            <BottomNavigationAction label="Crimson Service Desk Portal" value={"https://jira.esg.wsu.edu/servicedesk/customer/portal/34"} icon={<Feedback/>}/>
            <BottomNavigationAction label="Tech Tools User Guide" value={"https://confluence.esg.wsu.edu/display/KB/Tech+Tools+Users+Guide"} icon={<LibraryBooks />} />
          </BottomNavigation>
        </div>
        );
  }
}