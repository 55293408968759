import { CircularProgress, Paper, Table, TableRow, TableHead, TableBody, TableCell, TableContainer, TablePagination } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

export const OktaGroups = ({userId}) => {
    const [state, setState] = useState({isLoaded: false, data: null, error: null});

    const {authState} = useOktaAuth();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
      };

    useEffect(() =>{

        fetch(`${process.env.REACT_APP_AWS_API_GATEWAY_ENDPOINT}/user/groups/okta`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": authState.accessToken.accessToken
            },
            body: JSON.stringify({
                "userId": userId,
                "callerId": authState.accessToken.claims.sub
            })
        })
        .then( (res) => res.json())
        .then(
            (result) => {
                setState((prevState) => ({...prevState, data: result.Groups, isLoaded: true}));
            },
            (error) => {
                setState((prevState) => ({...prevState, isLoaded: true, error: error}));
            }
        )

    }, [])


    if(!state.isLoaded){
        return <div key="okta-groups-loading" id="container-div"><CircularProgress key="okta-groups-loader" id="progress" size={100} thickness={10}/></div>;
    }else if(state.error){
        return <div key="okta-groups-error-container" id="container-div"><p key="okta-groups-error">{state.error.message}</p></div>;
    }else if(state.data.length === 0){
        return <div key="okta-groups-no-records-container" id="container-div"><p key="okta-groups-no-records">No records found</p></div>;
    }else{
        return(
            <div key="okta-groups-div" id="container-div">
            <Paper key="okta-groups-paper">
            <TableContainer key="okta-groups-container" sx={{ maxHeight: 440 }}>
              <Table key="okta-groups-table" stickyHeader aria-label="sticky table">
                <TableHead key="okta-groups-table-head">
                  <TableRow key="okta-groups-header-row">
                    <TableCell key="groupd-id">
                        Group ID
                    </TableCell>
                    <TableCell key="group-name">
                        Group Name
                    </TableCell>
                    <TableCell key="group-desc">
                        Group Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                        console.log(row);
                      return (
                        <TableRow hover={true} role="checkbox" tabIndex={-1} key={row.username + "-" + row.event_time}>
                            <TableCell key={row.ID + "-id"}>
                                {row.ID}
                            </TableCell>
                            <TableCell key={ row.ID + "-name"}>
                                {row.Profile.Name}
                            </TableCell>
                            <TableCell key={row.ID + "-description"}>
                                {row.Profile.Description}
                            </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination key="table-pagination"
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={state.data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
            </div>)
    }
}